












































import axios from '@/utils/axios';
import { Vue, Component } from 'vue-property-decorator';
@Component
export default class Statistics extends Vue {
  public stats = {};

  async mounted() {
    this.stats = (await axios.get('stats')).data;
  }
}
